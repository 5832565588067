import styled from '@emotion/styled'
import { FlexBox } from 'app/components/Layout/FlexBox'
import { theme } from 'app/theme'
import { rgba } from 'emotion-rgba'
import { motion } from 'framer-motion'
import React, { memo } from 'react'

import { Benefits } from './Benefits'
import { BottomMenuItem, Props as BottomMenuItemProps } from './BottomMenuItem'
import { Contacts } from './Contacts'
import { MenuItem, Props as MenuItemProps } from './MenuItem'

export interface Props {
  address?: string
  benefitsChecklist?: string[]
  benefitsImage?: string
  benefitsTitle?: string
  bottomMenuItems?: BottomMenuItemProps[]
  country?: string
  email?: string
  facebookURL?: string
  IBEID?: number
  instagramURL?: string
  languageCode: string
  languagePrefix: string | null
  locality?: string
  menuItems: MenuItemProps[]
  modalStatus?: boolean
  navigationStatus?: boolean
  phone?: string
  postalCode?: string
  siteName?: string
  whatsappURL?: string
}

export const Navigation = memo(function Navigation({
  address,
  benefitsChecklist,
  benefitsImage,
  benefitsTitle,
  bottomMenuItems,
  country,
  email,
  facebookURL,
  IBEID,
  instagramURL,
  languageCode,
  languagePrefix,
  locality,
  menuItems,
  modalStatus,
  navigationStatus,
  phone,
  postalCode,
  siteName,
  whatsappURL,
}: Props) {
  const variants = {
    visible: (i: any) => ({
      opacity: 1,
      x: 0,
      transition: {
        damping: 25,
        delay: i * 0.25,
        duration: 0.4,
        type: 'spring',
      },
    }),
    hidden: { opacity: 0, x: '-3.75rem' },
  }

  return (
    <Container row>
      <LeftSide>
        {menuItems ? (
          <Nav>
            <NavList>
              {menuItems.map((item, index) => (
                <motion.li
                  key={index}
                  custom={index}
                  animate={navigationStatus ? 'visible' : 'hidden'}
                  variants={variants}
                >
                  <MenuItem {...item} />
                </motion.li>
              ))}
            </NavList>
          </Nav>
        ) : null}
        {bottomMenuItems ? (
          <BottomMenu
            className={navigationStatus ? 'visible' : undefined}
            row
            space="between"
          >
            {bottomMenuItems.map((item, index) => (
              <BottomMenuItem key={index} {...item} />
            ))}
          </BottomMenu>
        ) : null}
      </LeftSide>
      <RightSide>
        <Benefits
          checklist={benefitsChecklist}
          languageCode={languageCode}
          languagePrefix={languagePrefix}
          IBEID={IBEID}
          image={benefitsImage}
          modalStatus={modalStatus}
          title={benefitsTitle}
        />
        <Contacts
          address={address}
          country={country}
          email={email}
          facebookURL={facebookURL}
          instagramURL={instagramURL}
          locality={locality}
          modalStatus={modalStatus}
          phone={phone}
          postalCode={postalCode}
          siteName={siteName}
          whatsappURL={whatsappURL}
        />
      </RightSide>
    </Container>
  )
})

const Container = styled(FlexBox)`
  width: 100%;
  height: 100%;
  background: ${({ theme }) => theme.colors.variants.neutralLight3};
  position: fixed;
  top: 0;
  bottom: 0;
  left: 0;

  @media (max-width: 1199px) {
    display: block;
    overflow-y: auto;
    padding: 5rem 1.9375rem;
    -webkit-overflow-scrolling: auto;
    &:before {
      content: '';
      width: 100%;
      height: 100px;
      background: linear-gradient(
        ${rgba(theme.colors.variants.neutralLight3, 1)} 65%,
        ${rgba(theme.colors.variants.neutralLight3, 0)}
      );
      position: fixed;
      top: 0;
      left: 0;
      z-index: 2;
    }
  }
`

const LeftSide = styled(FlexBox)`
  width: 60%;
  height: 100%;
  position: relative;

  @media (max-width: 1199px) {
    width: 100%;
    height: auto;
  }
`

const Nav = styled.nav``

const NavList = styled.ul`
  display: flex;
  flex-flow: column wrap;
  position: absolute;
  top: 10vh;
  right: 6.667vw;
  bottom: 15vh;
  left: 6.667vw;

  li {
    margin-top: 4vh;
  }

  @media (max-width: 1199px) {
    display: block;
    position: relative;
    top: auto;
    right: auto;
    bottom: auto;
    left: auto;

    li {
      opacity: 1 !important;
      transform: none !important;
    }
  }
`

const BottomMenu = styled(FlexBox)`
  opacity: 0;
  position: absolute;
  bottom: 7vh;
  left: 6.667vw;
  transform: translateX(-1.875rem);
  transition: 0.6s ease-in-out;
  &.visible {
    opacity: 1;
    transform: translateX(0);
    transition-delay: 1s;
  }

  @media (max-width: 1199px) {
    display: block;
    position: relative;
    bottom: auto;
    left: auto;
    opacity: 1 !important;
    transform: none !important;
    &.visible {
      transition-delay: 0s;
    }
  }
`

const RightSide = styled.div`
  width: 40%;
  height: 100%;
  padding-right: 3.889vw;
  position: relative;

  @media (max-width: 1199px) {
    width: 100%;
    height: auto;
    padding-right: 0;
  }
`
