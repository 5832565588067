import styled from '@emotion/styled'
import { Button } from 'app/components/Common/Button'
import { FlexBox } from 'app/components/Layout/FlexBox'
import { useVocabularyData } from 'app/utils/vocabulary'
import React, { memo } from 'react'
import { Check, Gift } from 'react-feather'

export interface Props {
  checklist?: string[]
  languageCode: string
  languagePrefix: string | null
  IBEID?: number
  image?: string
  modalStatus?: boolean
  title?: string
}

export const Benefits = memo(function Benefits({
  checklist,
  image,
  IBEID,
  languageCode,
  languagePrefix,
  modalStatus,
  title,
}: Props) {
  return (
    <Container className={modalStatus ? 'open' : undefined}>
      {image ? (
        <Background style={{ backgroundImage: `url(${image})` }} />
      ) : null}
      <Wrapper>
        <Gift className="icon-gift" />
        {title ? <Title>{title}</Title> : null}
        {checklist
          ? checklist.map((item, index) => (
              <Item key={index} dial={4} row>
                <SVG dial={5}>
                  <Check />
                </SVG>
                <Label>
                  {
                    // @ts-ignore
                    item.label
                  }
                </Label>
              </Item>
            ))
          : null}
      </Wrapper>
      {IBEID ? (
        <CTA
          URL={`https://www.simplebooking.it/ibe/hotelbooking/search?hid=${IBEID}&lang=${
            languagePrefix ? languagePrefix.toLocaleUpperCase() : 'IT'
          }`}
          label={useVocabularyData('book-now', languageCode)}
          rel="noopener"
          target="_blank"
          variant="L"
        />
      ) : null}
    </Container>
  )
})

const Container = styled.div`
  background: ${({ theme }) => theme.colors.variants.neutralLight2};
  opacity: 0;
  position: relative;
  transition: 0.3s ease-in-out;
  &.open {
    opacity: 1;
    transition: 0.6s 0.4s ease-in-out;
  }
  &:before {
    content: '';
    width: 100%;
    height: 100%;
    background: ${({ theme }) => theme.colors.variants.neutralDark1};
    backface-visibility: hidden;
    opacity: 0.4;
    position: absolute;
    top: 0;
    left: 0;
    z-index: 2;
  }

  .icon-gift {
    width: auto;
    height: 1.25rem;
    stroke: ${({ theme }) => theme.colors.variants.primaryLight1};
    margin-bottom: 0.75rem;
  }

  @media (max-width: 1199px) {
    display: none;
  }
`

const Background = styled.div`
  width: 100%;
  height: 100%;
  background-position: center;
  background-repeat: no-repeat;
  background-size: cover;
  position: absolute;
  top: 0;
  left: 0;
`

const Wrapper = styled.div`
  padding: 13vh 7.361vw;
  position: relative;
  z-index: 2;

  @media (max-height: 800px) {
    padding-top: 8vh;
    padding-bottom: 8vh;
  }
`

const Title = styled.div`
  max-width: 270px;
  color: ${({ theme }) => theme.colors.variants.neutralLight4};
  font-family: ${({ theme }) => theme.fontFamily.heading};
  font-size: 2rem;
  line-height: 2.5rem;
  margin-bottom: 2.5rem;
`

const Item = styled(FlexBox)`
  margin-top: 1.5rem;
`

const SVG = styled(FlexBox)`
  width: 1.875rem;
  height: 1.875rem;
  background: ${({ theme }) => theme.colors.variants.primaryLight1};
  border-radius: 50%;
  margin-right: 1rem;

  svg {
    width: auto;
    height: 0.875rem;
    stroke: ${({ theme }) => theme.colors.variants.neutralLight4};
    stroke-width: 3;
  }
`

const Label = styled.div`
  width: calc(100% - 2.25rem);
  color: ${({ theme }) => theme.colors.variants.neutralLight4};
  font-family: ${({ theme }) => theme.fontFamily.paragraph};
  font-size: 0.875rem;
  line-height: 1.25rem;
`

const CTA = styled(Button)`
  display: block;
  position: relative;
  z-index: 3;
`
