import styled from '@emotion/styled'
import { Link } from 'gatsby'
import React, { memo } from 'react'
import { Image, Tag } from 'react-feather'

export interface Props {
  icon?: 'tag' | 'image'
  id?: string
  title: string
  languageCode?: string
  languagePrefix?: string | null
  URL: string
}

export const BottomMenuItem = memo(function BottomMenuItem({
  icon,
  title,
  URL,
}: Props) {
  if (!title) {
    return null
  }

  const renderIcon = () => {
    switch (icon) {
      case 'tag':
        return <Tag />
        break
      default:
        return <Image />
        break
    }
  }

  return (
    <Container activeClassName="active" to={URL}>
      {icon ? renderIcon() : null}
      {title}
    </Container>
  )
})

const Container = styled(Link)`
  display: flex;
  align-items: center;
  color: ${({ theme }) => theme.colors.variants.neutralDark2};
  font-family: ${({ theme }) => theme.fontFamily.heading};
  font-size: 1.375rem;
  line-height: 2.5rem;
  margin-right: 3rem;
  transition: 0.2s ease-in-out;
  &:last-of-type {
    margin-right: 0;
  }
  &:hover,
  &.active {
    color: ${({ theme }) => theme.colors.variants.primaryLight1};
  }

  svg {
    width: auto;
    height: 1.375rem;
    margin-right: 0.75rem;
    stroke: ${({ theme }) => theme.colors.variants.neutralDark1};
  }

  @media (max-width: 1199px) {
    font-size: 3.3vh;
    line-height: 4.5vh;
    margin-top: 4vh;
    margin-right: 0;

    svg {
      display: none;
    }
  }
`
