import styled from '@emotion/styled'
import { FlexBox } from 'app/components/Layout/FlexBox'
import React, { memo } from 'react'
import { isMobile } from 'react-device-detect'
import { Facebook, Instagram } from 'react-feather'

export interface Props {
  address?: string
  country?: string
  email?: string
  facebookURL?: string
  instagramURL?: string
  locality?: string
  modalStatus?: boolean
  phone?: string
  postalCode?: string
  siteName?: string
  whatsappURL?: string
}

export const Contacts = memo(function Contacts({
  address,
  country,
  email,
  facebookURL,
  instagramURL,
  locality,
  modalStatus,
  phone,
  postalCode,
  siteName,
  whatsappURL,
}: Props) {
  return (
    <Container className={modalStatus ? 'open' : undefined}>
      {siteName ? <SiteName>{siteName}</SiteName> : null}
      <Wrapper dial={7} row space="between">
        <LeftSide>
          {address ? (
            <Address>
              {address}
              <Separator />
              {`${postalCode} ${locality}, ${country}`}
            </Address>
          ) : null}
          {phone ? (
            <Phone
              href={`tel:${phone}`}
              onClick={() => {
                if (isMobile) {
                  typeof window.gtag !== 'undefined' &&
                    window.gtag('event', 'click', {
                      event_category: 'Website',
                      event_label: 'Phone Number',
                    })

                  typeof window._paq !== 'undefined' &&
                    window._paq.push([
                      'trackEvent',
                      'Website',
                      'Click',
                      'Phone Number',
                    ])
                }
              }}
            >
              {phone}
            </Phone>
          ) : null}
          {phone && email ? <Separator /> : null}
          {email ? (
            <Email
              href={`mailto:${email}`}
              onClick={() => {
                typeof window.gtag !== 'undefined' &&
                  window.gtag('event', 'click', {
                    event_category: 'Website',
                    event_label: 'Email Address',
                  })

                typeof window._paq !== 'undefined' &&
                  window._paq.push([
                    'trackEvent',
                    'Website',
                    'Click',
                    'Email Address',
                  ])
              }}
            >
              {email}
            </Email>
          ) : null}
        </LeftSide>
        <Social dial={4} row>
          {facebookURL ? (
            <SocialItem
              aria-label="Facebook"
              href={facebookURL}
              rel="noreferrer"
              target="_blank"
            >
              <Facebook />
            </SocialItem>
          ) : null}
          {instagramURL ? (
            <SocialItem
              aria-label="Instagram"
              href={instagramURL}
              rel="noreferrer"
              target="_blank"
            >
              <Instagram />
            </SocialItem>
          ) : null}
          {whatsappURL ? (
            <SocialItem
              aria-label="Whatsapp"
              href={whatsappURL}
              rel="noreferrer"
              target="_blank"
            >
              <svg
                xmlns="http://www.w3.org/2000/svg"
                width="23.998"
                height="24"
                viewBox="0 0 23.998 24"
              >
                <path
                  d="M20333-1737a11.906,11.906,0,0,1-6.6-1.983l-4.617,1.47,1.5-4.455A11.869,11.869,0,0,1,20321-1749a12.009,12.009,0,0,1,12-12,12.013,12.013,0,0,1,12,12A12.013,12.013,0,0,1,20333-1737Zm-4.523-18.511a1.7,1.7,0,0,0-1.225.429l-.035.035a3.828,3.828,0,0,0-1.189,2.882,6.757,6.757,0,0,0,1.416,3.611l.008.01c.012.016.033.047.063.092a14.536,14.536,0,0,0,5.908,5.183,10.229,10.229,0,0,0,3.514.988,2.871,2.871,0,0,0,.689-.084,3.359,3.359,0,0,0,2.357-1.692,2.972,2.972,0,0,0,.207-1.661c-.072-.124-.234-.2-.479-.317-.025-.013-.057-.027-.084-.041l-.113-.055c-.285-.144-2.051-1.014-2.387-1.133a.873.873,0,0,0-.3-.062.668.668,0,0,0-.555.322l-.148.208a12.716,12.716,0,0,1-.791,1.032.673.673,0,0,1-.5.205.917.917,0,0,1-.34-.067c-.029-.013-.061-.027-.1-.042l-.037-.016a8.788,8.788,0,0,1-2.678-1.68,10.511,10.511,0,0,1-1.945-2.419.557.557,0,0,1,.129-.739l.008-.012c.107-.131.209-.239.318-.354l.01-.011c.061-.061.127-.133.193-.209l.045-.05.018-.021a2.033,2.033,0,0,0,.328-.481.722.722,0,0,0-.053-.651c-.064-.135-.486-1.156-.729-1.752-.1-.229-.187-.448-.266-.63l-.086-.214c-.227-.552-.4-.581-.76-.6-.057,0-.115,0-.178,0h-.01C20328.627-1755.508,20328.557-1755.51,20328.479-1755.51Z"
                  transform="translate(-20321 1760.999)"
                />
              </svg>
            </SocialItem>
          ) : null}
        </Social>
      </Wrapper>
    </Container>
  )
})

const Container = styled.div`
  max-width: 440px;
  color: ${({ theme }) => theme.colors.variants.neutralDark2};
  font-family: ${({ theme }) => theme.fontFamily.paragraph};
  opacity: 0;
  position: absolute;
  bottom: 7vh;
  right: 3.889vw;
  left: 0;
  transition: 0.3s ease-in-out;
  transform: translateY(1.875rem);
  &.open {
    opacity: 1;
    transform: none;
    transition: 0.6s 0.4s ease-in-out;
  }

  @media (max-width: 1199px) {
    opacity: 1 !important;
    margin-top: 3.75rem;
    position: relative;
    bottom: auto;
    right: auto;
    left: auto;
    transform: none !important;
    &.open {
      transition: none !important;
    }
  }

  @media (max-height: 700px) {
    display: none;
  }
`

const SiteName = styled.div`
  font-size: 0.6875rem;
  font-weight: 800;
  letter-spacing: 0.0688rem;
  line-height: 0.875rem;
  margin-bottom: 9px;
  text-transform: uppercase;
`

const Wrapper = styled(FlexBox)`
  @media (max-width: 1199px) {
    display: block;
  }
`

const LeftSide = styled.div``

const Address = styled.div`
  font-size: 0.75rem;
  line-height: 1.125rem;
`

const Phone = styled.a`
  font-size: 0.75rem;
  line-height: 1.125rem;
  &:hover {
    text-decoration: underline;
  }
`

const Email = styled.a`
  font-size: 0.75rem;
  line-height: 1.125rem;
  &:hover {
    text-decoration: underline;
  }
`

const Separator = styled.span`
  display: inline-block;
  width: 4px;
  height: 4px;
  background: ${({ theme }) => theme.colors.variants.neutralDark2};
  border-radius: 50%;
  margin: 0 5px;
  transform: translateY(-2px);
`

const Social = styled(FlexBox)`
  margin-left: auto;

  @media (max-width: 1199px) {
    margin-top: 1.5rem;
  }
`

const SocialItem = styled.a`
  margin-right: 1.5rem;
  &:last-of-type {
    margin-right: 0;
  }
  &[aria-label='Facebook'],
  &[aria-label='Whatsapp'] {
    &:hover {
      svg {
        fill: ${({ theme }) => theme.colors.variants.primaryLight1};
      }
    }

    svg {
      fill: ${({ theme }) => theme.colors.variants.neutralDark3};
      stroke-width: 0;
    }
  }
  svg {
    width: auto;
    height: 1.25rem;
    stroke: ${({ theme }) => theme.colors.variants.neutralDark3};
    &:hover {
      stroke: ${({ theme }) => theme.colors.variants.primaryLight1};
    }
  }
`
