import styled from '@emotion/styled'
import { Link } from 'gatsby'
import React, { memo } from 'react'

export interface Props {
  id?: string
  title: string
  languageCode?: string
  languagePrefix?: string | null
  URL: string
}

export const MenuItem = memo(function MenuItem({ title, URL }: Props) {
  if (!title) {
    return null
  }

  return (
    <Container activeClassName="active" to={URL}>
      {title}
    </Container>
  )
})

const Container = styled(Link)`
  display: flex;
  align-items: center;
  color: ${({ theme }) => theme.colors.variants.neutralLight4};
  font-family: ${({ theme }) => theme.fontFamily.paragraph};
  font-size: 0.6875rem;
  font-weight: 800;
  letter-spacing: 0.0688rem;
  line-height: 0.875rem;
  text-transform: uppercase;
  transition: 0.1s ease-in-out;
  &:hover {
    color: ${({ theme }) => theme.colors.variants.neutralLight2};
  }
  &:last-of-type {
    &:after {
      display: none;
    }
  }
  &:after {
    content: '';
    width: 2px;
    height: 10px;
    background: ${({ theme }) => theme.colors.variants.neutralLight4};
    margin: 0 18px;
  }

  @media (max-width: 1023px) {
    width: 100%;
    justify-content: center;
    margin-top: 0.75rem;
    &:after {
      display: none;
    }
  }
`
