import {
  Header as HeaderComponent,
  Variant,
} from 'app/components/Common/Header'
import { Props as BottomMenuItemProps } from 'app/components/Common/Header/BottomMenuItem'
import { Props as MenuItemProps } from 'app/components/Common/Header/MenuItem'
import { graphql, StaticQuery } from 'gatsby'
import { compact } from 'lodash'
import React, { memo } from 'react'

import { getHeaderProps } from './getHeaderProps'

export interface Props {
  address?: string
  benefits?: any
  bottomMenuItems: { [key: string]: BottomMenuItemProps[] }[]
  country?: string
  email?: string
  facebookURL?: string
  IBEID?: number
  instagramURL?: string
  languageCode?: string
  languagePrefix?: string | null
  locality?: string
  logo?: string
  logoSticky?: string
  menuItems: { [key: string]: MenuItemProps[] }[]
  pageID?: string
  phone?: string
  postalCode?: string
  siteName?: string
  whatsappNumber?: string
  whatsappURL?: string
  variant?: Variant
}

export const Header = memo(function Header({
  address,
  benefits,
  bottomMenuItems,
  country,
  email,
  facebookURL,
  IBEID,
  instagramURL,
  languageCode,
  languagePrefix,
  locality,
  logo,
  logoSticky,
  menuItems,
  pageID,
  phone,
  postalCode,
  siteName,
  whatsappNumber,
  whatsappURL,
  variant = 'default',
}: Props) {
  return (
    <StaticQuery
      query={graphql`
        query HeaderData {
          allSitePage {
            nodes {
              pageContext
              path
            }
          }
        }
      `}
      render={(data) => {
        const props = getHeaderProps(
          data,
          languageCode || 'it-IT',
          languagePrefix || null,
          bottomMenuItems,
          menuItems,
          pageID,
        )

        const benefitsTranslation = compact(
          benefits?.translations.filter(
            (t: any) => t.languages_code.code === languageCode,
          ),
        )[0] as any

        const benefitsChecklist = benefitsTranslation?.benefits || undefined
        const benefitsImage =
          benefits.image.file.childImageSharp.gatsbyImageData.images.fallback
            .src || undefined
        const benefitsTitle = benefitsTranslation?.title || undefined

        return props ? (
          <HeaderComponent
            address={address}
            benefitsChecklist={benefitsChecklist}
            benefitsImage={benefitsImage}
            benefitsTitle={benefitsTitle}
            country={country}
            email={email}
            facebookURL={facebookURL}
            IBEID={IBEID}
            instagramURL={instagramURL}
            locality={locality}
            logo={logo}
            logoSticky={logoSticky}
            pageID={pageID}
            phone={phone}
            postalCode={postalCode}
            siteName={siteName}
            whatsappNumber={whatsappNumber}
            whatsappURL={whatsappURL}
            variant={variant}
            {...props}
          />
        ) : null
      }}
    />
  )
})
