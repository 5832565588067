import styled from '@emotion/styled'
import { theme } from 'app/theme'
import { rgba } from 'emotion-rgba'
import React, { memo, useEffect } from 'react'

export interface Props {
  className?: string
  IBEID?: number
  languagePrefix: string
}

export const Syncrobox = memo(function Syncrobox({
  className,
  IBEID = 1,
  languagePrefix,
}: Props) {
  useEffect(() => {
    const script = document.createElement('script')
    script.async = true

    script.innerHTML = `
    (function (i, s, o, g, r, a, m) {
        i['SBSyncroBoxParam'] = r; i[r] = i[r] || function () {
            (i[r].q = i[r].q || []).push(arguments)
        }, i[r].l = 1 * new Date(); a = s.createElement(o),
        m = s.getElementsByTagName(o)[0]; a.async = 1; a.src = g; m.parentNode.insertBefore(a, m)
    })(window, document, 'script', 'https://cdn.simplebooking.it/search-box-script.axd?IDA=${IBEID}','SBSyncroBox');
    SBSyncroBox({
        CodLang: '${languagePrefix.toLocaleUpperCase()}',
        
        Styles: {
          Theme: 'light-pink',
          FontFamily: "'${theme.fontFamily.heading}'",
          CustomBGColor: '${theme.colors.variants.neutralLight4}',
          CustomButtonBGColor: '${theme.colors.variants.primaryLight1}',
          CustomButtonHoverBGColor: '${theme.colors.getHoverColor(
            theme.colors.variants.primaryLight1,
          )}',
          CustomLinkColor: '${theme.colors.variants.neutralDark1}',
          CustomIconColor: '${theme.colors.variants.neutralLight1}',
          CustomBoxShadowColorFocus: '${theme.colors.variants.neutralDark1}',
          CustomBoxShadowColorHover: '${theme.colors.variants.neutralDark1}',
          CustomAccentColor: '${theme.colors.variants.primaryLight1}',
          CustomAccentColorHover: '${theme.colors.variants.primaryLight1}',
          CustomColorHover: '${theme.colors.variants.primaryLight1}',
          CustomLabelColor: '${theme.colors.variants.primaryLight1}',
          CustomLabelHoverColor: '${theme.colors.variants.primaryLight1}',
        },

        Labels: {
          CheckinDate: {'DE': 'Ankunft', 'EN': 'Arrival', 'ES': 'Llegada', 'FR': 'Arrivée', 'IT': 'Arrivo', 'RU': 'Пребытие'},
          CheckoutDate: {'DE': 'Abfahrt', 'EN': 'Departure', 'ES': 'Salida', 'FR': 'Départ', 'IT': 'Partenza', 'RU': 'Выезд'},
          NumPersons: {'DE': 'Gäste', 'EN': 'Guests', 'ES': 'Invitados', 'FR': 'Invités', 'IT': 'Ospiti', 'RU': 'Гостей'},
          NumAdults: {'DE': 'Personen', 'EN': 'Adults', 'ES': 'Adultos', 'FR': 'Adultes', 'IT': 'Adulti', 'RU': 'Взрослые'},
          NumKids: {'DE': 'Kinder', 'EN': 'Kids', 'ES': 'Niños', 'FR': 'Enfants', 'IT': 'Bambini', 'RU': 'Дети'},
          PromoCode: {'DE': 'Coupon', 'EN': 'Coupon', 'ES': 'Cupón', 'FR': 'Coupon', 'IT': 'Coupon', 'RU': 'Купон'},
          ModCancReservation: {'DE': 'Ändern Sie Ihre Reservierung', 'EN': 'Coupon', 'ES': 'Cambia tu reserva', 'FR': 'Modifier votre réservation', 'IT': 'Modifica la prenotazione', 'RU': 'Измените бронирование'},
        }
        
    });
    `

    document.body.appendChild(script)
    return () => {
      document.body.removeChild(script)
    }
  }, [])

  return (
    <Container className={className}>
      <Widget id="sb-container" />
    </Container>
  )
})
const Container = styled.div`
  position: absolute;
  top: calc(100vh - 13.625rem);
  right: 0;
  left: 0;
  text-align: center;
  &.fixed {
    animation: syncroFadeInTop 0.3s;
    position: fixed;
    top: 4.9375rem;
    background: ${({ theme }) => theme.colors.variants.neutralLight1};
    box-shadow: ${rgba(theme.colors.variants.neutralDark1, 0.15)} 0 3px 10px;
    #sb-container {
      display: block;
      .sb__form-field-input {
        background: transparent !important;
      }
      .sb-custom-color {
        background: ${({ theme }) =>
          theme.colors.variants.neutralLight3} !important;
      }
      .sb__dates {
        width: 45% !important;
        .sb__form-field--checkin,
        .sb__form-field--checkout {
          width: 50% !important;
        }
      }
      .sb__guests-rooms {
        width: calc(30% - 176px) !important;
      }
      #sb-container_sb__form-field--checkavailability {
        width: 25% !important;
      }
      .sb-screen-m .sb__calendar {
        margin-top: 0;
      }
      .sb-screen-m .sb__guests {
        margin-top: 0;
      }
      .sb-screen-m .sb__footer-promo {
        margin-top: 72px;
        margin-left: calc(-25vw - 159px);
      }
      .sb__footer {
        .sb__footer-actions {
          a {
            right: 25vw;
          }
        }
      }
    }
  }

  @media (max-width: 1439px) {
    &.fixed {
      #sb-container {
        .sb__dates {
          width: 50% !important;
        }
        .sb__guests-rooms {
          width: 25% !important;
        }
        #sb-container_sb__form-field--checkavailability {
          width: 25% !important;
        }
      }
    }

    #sb-container {
      #sb-container_sb__form-field--checkavailability {
        margin-left: 0 !important;
      }
      .sb__footer {
        display: none;
      }
    }
  }

  @keyframes syncroFadeInTop {
    0% {
      top: 0;
    }
    100% {
      top: 4.9375rem;
    }
  }

  #sb-container {
    display: inline-block;
    margin: auto;
    text-align: left;
    .sb {
      display: flex !important;
      padding: 0 !important;
    }
    .sb__guests-children-age-select.sb-custom-bg-color {
      background: ${({ theme }) =>
        theme.colors.variants.neutralLight3} !important;
    }
    .sb-container_sb__form {
      display: flex !important;
    }
    .sb-custom-box-shadow-color,
    .sb-custom-box-shadow-color-hover,
    .focus .sb-custom-box-shadow-color-focus {
      box-shadow: none !important;
      border-right: none !important;
      padding: 0;
      &:before {
        display: none !important;
      }
      &:hover {
        box-shadow: none !important;
      }
      &.sb__guests,
      &.sb__footer-promo {
        box-shadow: ${rgba(theme.colors.variants.neutralDark1, 0.1)} 0 3px 10px !important;
      }
    }
    .sb-custom-box-shadow-color:focus {
      box-shadow: none !important;
    }
    .sb-custom-widget-element-hover-bg-color:hover {
      background: ${({ theme }) =>
        theme.colors.variants.neutralLight1} !important;
    }
    .sb-custom-box-shadow-color {
      border-color: ${({ theme }) =>
        theme.colors.variants.neutralLight3} !important;
    }
    #sb-container_sb__form-field--checkavailability {
      width: 276px !important;
      font-family: ${({ theme }) => theme.fontFamily.paragraph};
      font-size: 0.8125rem;
      font-weight: 700;
      letter-spacing: 1px;
      overflow: hidden;
      margin-top: 0;
      margin-left: 176px;
      text-overflow: ellipsis;
      text-transform: uppercase;
      transition: background 0.3s ease-in-out !important;
    }
  }

  .sb__form-field-label {
    display: inline-block;
  }

  .sb-custom-icon-color {
    margin-right: 1.5rem;
  }

  .sb-open-top .sb__calendar {
    margin-bottom: 1px;
  }

  .sb-screen-m .sb__calendar {
    margin-top: 1px;
  }

  .sb-open-top .sb__guests {
    margin-bottom: 1px;
  }

  .sb-screen-m .sb__guests {
    margin-top: 1px;
    margin-left: -8px;
  }

  .sb-open-top .sb__footer-promo {
    margin-bottom: 9px;
    margin-left: -451px;
  }

  .sb-screen-m .sb__footer-promo {
    margin-top: 73px;
    margin-left: -435px;
  }

  .sb__dates {
    display: flex;
    align-items: flex-end;
    width: auto !important;
    .sb__form-field {
      width: 50% !important;
    }
    .sb__form-field--checkin,
    .sb__form-field--checkout {
      width: 293px !important;
      height: 80px !important;
      border-right: 1px solid
        ${({ theme }) => theme.colors.variants.neutralLight1};
      margin: 0;
      padding-top: 6px;
      .sb__form-field-label {
        font-family: ${({ theme }) => theme.fontFamily.paragraph};
        font-size: 0.5625rem;
        font-weight: 800;
        letter-spacing: 0.0563rem;
        line-height: 0.6875rem;
        margin-bottom: 0.25rem;
        text-transform: uppercase;
      }
      .sb__form-field-date-number {
        font-family: ${({ theme }) => theme.fontFamily.heading};
        font-size: 1.875rem;
        line-height: 2.5rem;
        margin-right: 0.625rem;
        transform: translateY(-5px);
      }
      .sb__form-field-month-year {
        font-size: 0.75rem;
        font-weight: 700;
        letter-spacing: 0.075rem;
        line-height: 0.9375rem;
      }
      .sb__form-field-weekday {
        font-size: 0.75rem;
        line-height: 0.9375rem;
        text-transform: capitalize;
      }
      .sb__form-field-input {
        width: 100% !important;
        height: 30px !important;
        border-radius: 0 !important;
        box-shadow: none !important;
        left: 0 !important;
      }
    }
    .sb__form-field--checkin {
      padding-left: 1.5rem !important;
    }
    .sb__form-field--checkout {
      margin-left: 1.1875rem !important;
    }
    .sb__calendar {
      width: 642px !important;
      border-radius: 0 !important;
      box-shadow: ${rgba(theme.colors.variants.neutralDark1, 0.1)} 0 3px 10px !important;
      border: none !important;
    }
    .sb__calendar-btn {
      box-shadow: none !important;
      color: ${({ theme }) => theme.colors.variants.neutralLight1} !important;
      .icon {
        fill: ${({ theme }) => theme.colors.variants.neutralDark4} !important;
      }
    }
    .sb__calendar-days {
      .sb__calendar-day {
        border-radius: 0 !important;
        font-size: 11px;
      }
    }
  }

  .sb__property {
    display: none;
  }

  .sb__guests {
    border: none !important;
    border-radius: 0 !important;
    padding: 1rem !important;
    .sb__panel-actions {
      button {
        margin-top: 0 !important;
      }
      .sb__btn--secondary {
        background: ${({ theme }) => theme.colors.variants.neutralLight3};
        &:hover {
          background: ${({ theme }) =>
            theme.colors.variants.neutralDark4} !important;
        }
      }
    }
  }

  .sb__guests-rooms {
    width: auto !important;
    margin-top: 0;
    padding-top: 6px;
    padding-right: 0 !important;
    padding-left: 1.1875rem !important;
    .sb__guests {
      width: auto !important;
    }
    .sb__form-field-icon .icon {
      margin-left: 60px;
    }
    .sb__form-field {
      .sb__form-field-label {
        font-family: ${({ theme }) => theme.fontFamily.paragraph};
        font-size: 0.5625rem;
        font-weight: 800;
        letter-spacing: 0.0563rem;
        line-height: 0.6875rem;
        margin-bottom: 0.25rem;
        text-transform: uppercase;
      }
      .sb__form-field-date-number {
        font-family: ${({ theme }) => theme.fontFamily.heading};
        font-size: 1.875rem;
        line-height: 2.5rem;
        margin-right: 0.625rem;
        transform: translateY(-5px);
      }
      .sb__form-field-guests {
        width: 130px;
        font-size: 0.75rem;
        font-weight: 700;
        letter-spacing: 0.075rem;
        line-height: 0.9375rem;
        overflow: hidden;
        text-overflow: ellipsis;
        white-space: nowrap;
      }
      .sb__form-field-rooms {
        font-size: 0.75rem;
        line-height: 0.9375rem;
      }
      #sb-container_sb__form-field--guests {
        width: 98% !important;
        max-width: 480px;
        height: 30px !important;
        box-shadow: none !important;
        border-radius: 0 !important;
      }
    }
  }

  .sb__guests-room {
    border-bottom: 1px solid
      ${({ theme }) => theme.colors.variants.neutralLight3};
    margin-bottom: 1rem;
    padding-bottom: 1rem;
    .sb__guests-room-header {
      margin-bottom: 1rem;
    }
    .sb__guests-room-label {
      font-family: ${({ theme }) => theme.fontFamily.heading};
      font-size: 1.25rem;
      color: ${({ theme }) => theme.colors.variants.neutralDark4};
      line-height: 2rem;
    }
    .sb__guests-room-header-divider {
      display: none;
    }
    .sb__guests-children-age-select.sb-custom-bg-color {
      width: 2.5rem;
      height: 1.75rem;
      margin-right: 1.625rem;
      border-radius: 0 !important;
      text-align: center;
    }
  }

  .sb__guests-room-remove {
    width: 1.75rem;
    height: 1.75rem;
    border-left: 1px solid ${({ theme }) => theme.colors.variants.neutralLight1};
    margin-left: 0.5rem;
    padding-left: 0.5rem;
    svg {
      transform: scale(2) translateY(1px);
      * {
        fill: ${({ theme }) => theme.colors.variants.primaryLight1} !important;
      }
    }
  }

  .sb__btn {
    border-radius: 0;
    &.sb__btn--block.sb__btn--verify {
      height: 80px !important;
      font-size: 0.75rem;
      font-weight: 700;
      letter-spacing: 0.0375rem;
      padding: 0 1.125rem;
      text-align: center;
    }
  }

  .sb__footer {
    .sb__footer-actions {
      > a {
        display: none !important;
      }
      a {
        display: flex;
        align-items: center;
        justify-content: center;
        width: 176px !important;
        height: 80px;
        border-left: 1px solid
          ${({ theme }) => theme.colors.variants.neutralLight1};
        font-size: 0.625rem;
        font-weight: 800;
        letter-spacing: 0.0625rem;
        line-height: 0.9375rem;
        margin-top: 0;
        margin-right: 0;
        padding: 0 1.75rem;
        position: absolute;
        top: 0;
        right: 276px;
        text-align: left;
        .icon {
          order: 2;
          margin-left: 1.125rem;
        }
      }
      .sb__footer-promo-wrapper {
        width: 30%;
        text-transform: uppercase;
      }
      .sb__footer-promo {
        display: flex;
        border: none !important;
        border-radius: 0 !important;
        padding: 1rem !important;
        input {
          height: 1.5rem;
          border-radius: 0 !important;
        }
      }
    }
    .sb__panel-actions {
      .sb__btn--secondary {
        background: ${({ theme }) => theme.colors.variants.neutralLight3};
      }
    }
  }
`

const Widget = styled.div``
