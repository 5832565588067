import { css } from '@emotion/react'
import styled from '@emotion/styled'
import { theme } from 'app/theme/index'
import { Media, MediaContextProvider, mediaStyle } from 'app/theme/media'
import { Link } from 'gatsby'
import React, { memo, useEffect, useState } from 'react'
import { Minus, Plus } from 'react-feather'

export interface Props {
  childs?: ChildsProps[]
  className?: string
  id?: string
  title: string
  languageCode?: string
  languagePrefix?: string | null
  url: string
}

interface ChildsProps {
  id?: string
  title: string
  url: string
  weight?: number
}

export const MenuItem = memo(function MenuItem({
  childs,
  className,
  title,
  url,
}: Props) {
  if (!title) {
    return null
  }

  const [submenuVisibility, setSubmenuVisibility] = useState(false)

  useEffect(() => {
    setSubmenuVisibility(false)
  }, [])

  return (
    <>
      {url ? (
        <Anchor className={className} to={url} activeClassName="active">
          {title}
        </Anchor>
      ) : (
        <NoLink
          className={`${className}${submenuVisibility ? ' open' : ''}`}
          onClick={() =>
            submenuVisibility
              ? setSubmenuVisibility(false)
              : setSubmenuVisibility(true)
          }
        >
          {title}
          <style type="text/css">{mediaStyle}</style>
          <MediaContextProvider>
            <Media lessThan="desktopSmall">
              {submenuVisibility ? <Minus /> : <Plus />}
            </Media>
          </MediaContextProvider>
        </NoLink>
      )}
      {childs && childs.length > 0 ? (
        <Submenu className={`submenu${submenuVisibility ? ' open' : ''}`}>
          {childs
            .sort((a: any, b: any) => a.weight - b.weight)
            .map((item, index) => (
              <ChildItem key={index} {...item} />
            ))}
        </Submenu>
      ) : null}
    </>
  )
})

export const ChildItem = memo(function ChildItem({ title, url }: ChildsProps) {
  return (
    <SubmenuLi>
      <Anchor to={url} activeClassName="active">
        {title}
      </Anchor>
    </SubmenuLi>
  )
})

const Style = css`
  color: ${theme.colors.variants.neutralDark2};
  font-family: ${theme.fontFamily.heading};
  font-size: 3.3vh;
  font-weight: 400;
  line-height: 4.5vh;
  transition: 0.2s ease-out;
`

const Anchor = styled(Link)`
  &:hover,
  &.active {
    color: ${theme.colors.variants.primaryLight1};
  }

  ${Style}
`

const NoLink = styled.span`
  cursor: default;

  ${Style}

  @media (max-width: 1199px) {
    display: inline-flex;
    align-items: center;

    svg {
      margin-left: 1.125rem;
      stroke: ${({ theme }) => theme.colors.variants.neutralLight1};
      stroke-width: 1.5;
      transform: translateY(0.25rem);
    }
  }
`

const Submenu = styled.ul`
  @media (max-width: 1199px) {
    max-height: 0;
    overflow: hidden;
    transition: 0.5s cubic-bezier(0, 1, 0, 1);
    &.open {
      max-height: 350px;
      transition: 0.5s ease-in-out;
    }
  }
`

const SubmenuLi = styled.li`
  margin-top: 0.8vh !important;
  a {
    color: ${({ theme }) => theme.colors.variants.neutralLight1};
    font-family: ${({ theme }) => theme.fontFamily.paragraph};
    font-size: 1.6vh;
    line-height: 3.4vh;
  }
`
