import styled from '@emotion/styled'
import { Link } from 'gatsby'
import React, { memo } from 'react'

export interface Props {
  className?: string
  pageID?: string
  label: string
  languagePrefix: string | null
  URL: string
}

export const LanguageItem = memo(function LanguageItem({
  className,
  label,
  languagePrefix,
  URL,
}: Props) {
  return (
    <Anchor
      className={className}
      to={URL}
      lang={languagePrefix === label ? undefined : label}
    >
      {label}
      <svg
        xmlns="http://www.w3.org/2000/svg"
        width="9.545"
        height="8"
        viewBox="0 0 9.545 8"
      >
        <path
          d="M15.552,14.977l-4.788-8-4.757,8Z"
          transform="translate(15.552 14.977) rotate(180)"
        />
      </svg>
    </Anchor>
  )
})

const Anchor = styled(Link)`
  display: flex;
  align-items: center;
  justify-content: center;
  color: ${({ theme }) => theme.colors.variants.neutralLight4};
  font-family: ${({ theme }) => theme.fontFamily.paragraph};
  font-size: 0.75rem;
  font-weight: 800;
  letter-spacing: 0.075rem;
  line-height: 0.9375rem;
  text-transform: uppercase;
  transition: 0.3s ease-in-out;
  &.active {
    pointer-events: none;
  }
  &:hover {
    color: ${({ theme }) => theme.colors.variants.primaryLight1};
  }
  svg {
    width: auto;
    height: 0.5rem;
    fill: ${({ theme }) => theme.colors.variants.primaryLight1};
    margin-left: 0.5rem;
    transition: 0.3s ease-in-out;
  }
`
