import styled from '@emotion/styled'
import { FlexBox } from 'app/components/Layout/FlexBox'
import { useVocabularyData } from 'app/utils/vocabulary'
import React, { memo, useEffect, useState } from 'react'

interface Props {
  IBEID?: number
  languageCode: string
  languagePrefix: string | null
  whatsappURL?: string
}

export const Toolbars = memo(function Toolbars({
  IBEID,
  languageCode,
  languagePrefix,
  whatsappURL,
}: Props) {
  const [scrollDir, setScrollDir] = useState('up')

  useEffect(() => {
    const threshold = 0
    let lastScrollY = window.pageYOffset
    let ticking = false

    const updateScrollDir = () => {
      const scrollY = window.pageYOffset

      if (Math.abs(scrollY - lastScrollY) < threshold) {
        ticking = false
        return
      }
      setScrollDir(scrollY > lastScrollY ? 'down' : 'up')
      lastScrollY = scrollY > 0 ? scrollY : 0
      ticking = false
    }

    const onScroll = () => {
      if (!ticking) {
        window.requestAnimationFrame(updateScrollDir)
        ticking = true
      }
    }

    window.addEventListener('scroll', onScroll)

    return () => window.removeEventListener('scroll', onScroll)
  }, [scrollDir])

  return (
    <Container
      className={scrollDir == 'down' ? 'hidden' : ''}
      dial={5}
      row
      space="between"
      stretch
    >
      {IBEID ? (
        <Item
          href={`https://www.simplebooking.it/ibe/hotelbooking/search?hid=${IBEID}&lang=${
            languagePrefix ? languagePrefix.toLocaleUpperCase() : 'IT'
          }`}
          rel="noopener"
          target="_blank"
        >
          <Label>{useVocabularyData('book-now', languageCode)}</Label>
        </Item>
      ) : null}
      {whatsappURL ? (
        <Item
          aria-label="Whatsapp"
          className="header-whatsapp"
          href={whatsappURL}
          rel="noreferrer"
          target="_blank"
        >
          <svg
            xmlns="http://www.w3.org/2000/svg"
            width="23.998"
            height="24"
            viewBox="0 0 23.998 24"
          >
            <path
              d="M20333-1737a11.906,11.906,0,0,1-6.6-1.983l-4.617,1.47,1.5-4.455A11.869,11.869,0,0,1,20321-1749a12.009,12.009,0,0,1,12-12,12.013,12.013,0,0,1,12,12A12.013,12.013,0,0,1,20333-1737Zm-4.523-18.511a1.7,1.7,0,0,0-1.225.429l-.035.035a3.828,3.828,0,0,0-1.189,2.882,6.757,6.757,0,0,0,1.416,3.611l.008.01c.012.016.033.047.063.092a14.536,14.536,0,0,0,5.908,5.183,10.229,10.229,0,0,0,3.514.988,2.871,2.871,0,0,0,.689-.084,3.359,3.359,0,0,0,2.357-1.692,2.972,2.972,0,0,0,.207-1.661c-.072-.124-.234-.2-.479-.317-.025-.013-.057-.027-.084-.041l-.113-.055c-.285-.144-2.051-1.014-2.387-1.133a.873.873,0,0,0-.3-.062.668.668,0,0,0-.555.322l-.148.208a12.716,12.716,0,0,1-.791,1.032.673.673,0,0,1-.5.205.917.917,0,0,1-.34-.067c-.029-.013-.061-.027-.1-.042l-.037-.016a8.788,8.788,0,0,1-2.678-1.68,10.511,10.511,0,0,1-1.945-2.419.557.557,0,0,1,.129-.739l.008-.012c.107-.131.209-.239.318-.354l.01-.011c.061-.061.127-.133.193-.209l.045-.05.018-.021a2.033,2.033,0,0,0,.328-.481.722.722,0,0,0-.053-.651c-.064-.135-.486-1.156-.729-1.752-.1-.229-.187-.448-.266-.63l-.086-.214c-.227-.552-.4-.581-.76-.6-.057,0-.115,0-.178,0h-.01C20328.627-1755.508,20328.557-1755.51,20328.479-1755.51Z"
              transform="translate(-20321 1760.999)"
            />
          </svg>
        </Item>
      ) : null}
    </Container>
  )
})

const Container = styled(FlexBox)`
  width: 100%;
  position: fixed;
  bottom: 0;
  left: 0;
  z-index: 50;
  transition: 0.3s ease-out;
  &.hidden {
    transform: translateY(6rem);
  }
`

const Item = styled.a`
  display: flex;
  align-items: center;
  justify-content: center;
  width: calc(100% - 4.375rem);
  background: ${({ theme }) => theme.colors.variants.primaryLight1};
  color: ${({ theme }) => theme.colors.variants.neutralLight4};
  text-align: center;
  &:nth-of-type(2) {
    background: ${({ theme }) => theme.colors.variants.neutralLight1};
    width: 4.375rem;
    height: 4.375rem;

    svg {
      width: auto;
      height: 30px;
      fill: ${({ theme }) => theme.colors.variants.neutralLight4};
    }
  }
`

const Label = styled.div`
  font-family: ${({ theme }) => theme.fontFamily.paragraph};
  font-size: 0.75rem;
  font-weight: 700;
  letter-spacing: 0.5px;
  line-height: 0.9375rem;
  text-transform: uppercase;
  &:after {
    content: '';
    display: block;
    height: 2px;
    background: ${({ theme }) => theme.colors.variants.neutralLight4};
    margin-top: 0.25rem;
  }
`
