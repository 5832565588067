import styled from '@emotion/styled'
import { FlexBox } from 'app/components/Layout/FlexBox'
import { useVocabularyData } from 'app/utils/vocabulary'
import { memo } from 'react'

export interface Props {
  languageCode: string
  onClick: () => void
  modalStatus?: boolean
}

export const ModalClose = memo(function ModalClose({
  languageCode,
  onClick,
  modalStatus,
}: Props) {
  return (
    <Container
      className={modalStatus ? 'open' : undefined}
      dial={4}
      row
      onClick={onClick}
    >
      <Icon className="close-icon" />
      <Label>{useVocabularyData('close', languageCode)}</Label>
    </Container>
  )
})

const Container = styled(FlexBox)`
  cursor: pointer;
  opacity: 0;
  position: fixed;
  top: 2.25rem;
  left: 3.889vw;
  transform: translateX(-1.875rem);
  transition: 0.6s ease-in-out;
  z-index: 2;
  &:hover {
    .close-icon {
      &:before,
      &:after {
        transform: none;
      }
    }
  }
  &.open {
    opacity: 1;
    transform: none;
  }

  @media (max-width: 1199px) {
    left: 2.0625rem;
  }
`

const Icon = styled.div`
  width: 20px;
  height: 20px;
  position: relative;
  &:before,
  &:after {
    content: '';
    width: 26px;
    height: 2px;
    background: ${({ theme }) => theme.colors.variants.neutralDark4};
    position: absolute;
    top: 8px;
    left: -4px;
    transition: 0.15s ease-out;
  }
  &:before {
    transform: rotate(45deg);
  }
  &:after {
    transform: rotate(-45deg);
  }
`

const Label = styled.div`
  color: ${({ theme }) => theme.colors.variants.neutralDark4};
  font-family: ${({ theme }) => theme.fontFamily.paragraph};
  font-size: 0.6875rem;
  font-weight: 600;
  letter-spacing: 0.1031rem;
  line-height: 0.875rem;
  margin-left: 0.875rem;
  text-transform: uppercase;

  @media (max-width: 1199px) {
    display: none;
  }
`
